import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { Breadcrumb, Container, Segment } from 'semantic-ui-react'; // Add Popup from Semantic UI
import { defineMessages, injectIntl } from 'react-intl';
import { getBreadcrumbs, getContent } from '@plone/volto/actions';
import { getBaseUrl, hasApiExpander, flattenToAppURL } from '@plone/volto/helpers';
import { Api } from '@plone/volto/helpers';

const messages = defineMessages({
  home: {
    id: 'Home',
    defaultMessage: 'Home',
  },
  breadcrumbs: {
    id: 'Breadcrumbs',
    defaultMessage: 'Breadcrumbs',
  },
});

export class BreadcrumbsComponent extends Component {
  static propTypes = {
    getBreadcrumbs: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    root: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ).isRequired,
  };

  state = {
    items: [],
    current: -1,
    error: null,
  }

  apiGetNav = async () => {
    const api = new Api();

    // Function to fetch items and their children
    const fetchItemsAndChildren = async (item) => {
      const promise = await api.get(flattenToAppURL(item['@id']));
      const items = promise.items.filter((item) => (item['@type'] === 'Document' || item['@type'] === 'Link' || item['@type'] === 'Event' || item.type_title === 'Termin') && item.review_state === 'published');
      const children = await Promise.all(items.map(async (childItem) => {
        const promise = await api.get(flattenToAppURL(childItem['@id']));

        const childItems = promise.items.filter((item) => (item['@type'] === 'Document' || item['@type'] === 'Link' || item['@type'] === 'Event' || item.type_title === 'Termin') && item.review_state === 'published');
        const cleanChildItems = childItems.map((item) => ({ 'url': item['@id'], 'title': item.title }))
        return { 'url': childItem['@id'], 'title': childItem.title, 'children': cleanChildItems };
      }));
      return { 'url': item['@id'], 'title': item.title, 'children': children };
    };

    // Main logic to fetch items and their children
    const promise = await api.get('');
    const items = promise.items.filter((item) => (item['@type'] === 'Document' || item['@type'] === 'Link' || item['@type'] === 'Event' || item.type_title === 'Termin') && item.review_state === 'published');
    const navItems = await Promise.all(items.map(fetchItemsAndChildren));

    const current = this.findCurrent(navItems, this.props.pathname)
    this.setState({ items: navItems, current: current })

    return navItems;
  };

  findCurrent = (items, currentpath) => {
    let current = this.state.current;
    items.map((item, index) => {
      if (currentpath.includes(flattenToAppURL(item.url))) {
        current = index;
      }
    })
    return current;
  }

  componentDidMount() {
    this.apiGetNav()
    if (!hasApiExpander('breadcrumbs', getBaseUrl(this.props.pathname))) {
      this.props.getBreadcrumbs(getBaseUrl(this.props.pathname));
    }

  }

  componentDidUpdate(prevProps) {
    if (prevProps.pathname !== this.props.pathname) {
      if (!hasApiExpander('breadcrumbs', getBaseUrl(this.props.pathname))) {
        this.props.getBreadcrumbs(getBaseUrl(this.props.pathname));
      }
      let current = this.findCurrent(this.state.items, this.props.pathname)
      this.setState({ current: current })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.items !== nextState.items ||
      this.state.loading !== nextState.loading ||
      this.props.pathname !== nextProps.pathname ||
      this.state.current !== nextState.current ||
      this.props.items !== nextState.items
    );
  }

  render() {
    return (
      <Segment
        role="navigation"
        aria-label="Breadcrumbs"
        className="breadcrumbs"
        secondary
        vertical
      >
        <Container>
          <div className='breadcrumb-wrapper'>
            <Breadcrumb>
              <Link
                to={this.props.root || '/'}
                className="section"
                title="Home"
              >
                Startseite
              </Link>
              {this.props.items.map((item, index, items) => [
                <Breadcrumb.Divider key={`divider-${item.url}`} />,
                index < items.length - 1 ? (
                  <Link key={item.url} to={item.url} className="section">
                    {item.title}
                  </Link>
                ) : (
                  <Breadcrumb.Section key={item.url} active>
                    {item.title}
                  </Breadcrumb.Section>
                ),
              ])}
              <div className='green'>

              </div>
            </Breadcrumb>
            {this.state.current >= 0 && this.props.pathname && (
              <div className='breadcrumb-nav green'>
                {
                  this.state.items[this.state.current].children.map((item, index) => {
                    return (
                      <React.Fragment key={item.url}>
                        <Link key={item.url} to={flattenToAppURL(item.url)} className="section">
                          <h4 className={item.iscurrent ? "greenfont" : ""}> {item.title} </h4>
                        </Link>
                        <div className='breadcrumb-secondary'>
                          {Array.isArray(item.children) && (this.props.pathname.includes(flattenToAppURL(item.url))) &&
                            item.children.map((seconditem) => (
                              <Link key={seconditem.url} to={flattenToAppURL(seconditem.url)} className="section">
                                <div className={seconditem.iscurrent ? "greenfont" : ""} >{seconditem.title}</div>
                              </Link>
                            ))}
                        </div>
                      </React.Fragment>);
                  })}
              </div>
            )}
          </div>
        </Container>
      </Segment>

    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      items: state.breadcrumbs.items,
      root: state.breadcrumbs.root,
      current: state.breadcrumbs.current,
    }),
    { getBreadcrumbs, getContent },
  ),
)(BreadcrumbsComponent);
