/**
 * NewsItemView view component.
 * @module components/theme/View/NewsItemView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Image } from 'semantic-ui-react';
import {
  hasBlocksData,
  flattenToAppURL,
  flattenHTMLToAppURL,
} from '@plone/volto/helpers';
import { FormattedDate } from '@plone/volto/components';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';

/**
 * NewsItemView view component class.
 * @function NewsItemView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const NewsItemView = ({ content }) => {
  return (

    <Container className="view-wrapper newspage">
      {content.review_state === 'published' && content.effective && (
        <p>
          <FormattedDate date={content.effective}  />
        </p>
      )}
      {content.image && (
        <p className="block image align left">
          <Image
            className="documentImage newsimage"
            alt={content.title}
            title={content.title}
            src={flattenToAppURL(content.image.download)}
          />
        </p>
      )}

      <div className='news_text'>
        <RenderBlocks content={content} className="news_text" />

      </div>
    </Container>
  )
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
NewsItemView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default NewsItemView;
