import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, FormattedDate } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';

const FrontPageTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
    let link = null;
    let href = linkHref?.[0]?.['@id'] || '';

    if (isInternalURL(href)) {
        link = (
            <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
                {linkTitle || href}
            </ConditionalLink>
        );
    } else if (href) {
        link = <a href={href}>{linkTitle || href}</a>;
    }

    return (
        <>
            <div className="items">
                {items.map((item) => (
                    <ConditionalLink item={item} condition={!isEditMode} key={item['@id']}>
                        <div className="listing-item">
                            <div className="listing-body frontpage">
                                {item.Date && <FormattedDate date={item.Date.split("T")[0]} />}
                                <h4>{item.title ? item.title : item.id}</h4>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    </ConditionalLink>
                ))}
            </div>


            {link && <div className="footer">{link}</div>}
        </>
    );
};
FrontPageTemplate.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    linkMore: PropTypes.any,
    isEditMode: PropTypes.bool,
};
export default FrontPageTemplate;
