import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, FormattedDate } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';

const FrontPageTemplateTermin = ({ items, linkTitle, linkHref, isEditMode }) => {
    let link = null;
    let href = linkHref?.[0]?.['@id'] || '';

    if (isInternalURL(href)) {
        link = (
            <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
                {linkTitle || href}
            </ConditionalLink>
        );
    } else if (href) {
        link = <a href={href}>{linkTitle || href}</a>;
    }

    const getTimeString = (item) => {
        const startDate = item.start ? item.start.split("T")[0] : "";
        const endDate = item.end ? item.end.split("T")[0] : "";

        if (!startDate && !endDate) {
            return "";
        }

        if (startDate === endDate) {
            return <FormattedDate date={startDate} />;
        }

        return (
            <>
                {startDate && <FormattedDate date={startDate} />}
                {" - "}
                {endDate && <FormattedDate date={endDate} />}
            </>
        );
    };


    return (
        <>
            <div className="items">
                {items.map((item) => (
                    <ConditionalLink item={item} condition={!isEditMode} key={item['@id']}>
                        {console.log(item)}

                        <div className="listing-item">
                            <div className="listing-body frontpage">
                                <h4>{item.title ? item.title : item.id}</h4>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    </ConditionalLink>
                ))}
            </div>


            {link && <div className="footer">{link}</div>}
        </>
    );
};
FrontPageTemplateTermin.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    linkMore: PropTypes.any,
    isEditMode: PropTypes.bool,
};
export default FrontPageTemplateTermin;
