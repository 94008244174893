import React from 'react';
import NavItem from './NavItem';

const NavItems = ({ items, lang, ismobile, close }) => {
  return (
    <>
      {items.map((item) => (
        <NavItem item={item} lang={lang} key={item.url} ismobile={ismobile} close={close}/>
      ))}
    </>
  );
};

export default NavItems;
