/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment } from 'semantic-ui-react';

import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';

const messages = defineMessages({
    copyright: {
        id: 'Copyright',
        defaultMessage: 'Copyright',
    },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
    const { settings } = config;
    const lang = useSelector((state) => state.intl.locale);
    return (
        <>
            <div className='background-container'>
                <div className='background-cell' />
            </div>
            <Segment
                className='footer'
                id='footer'
            >

                <Container>
                    <List horizontal inverted>
                        <div role="listitem" className="item border">

                            <UniversalLink className="item" href={'/kontakt'}>
                                <FormattedMessage id="Kontakt" defaultMessage="Kontakt" />
                            </UniversalLink>
                        </div>
                        <div role="listitem" className="item border">
                            <UniversalLink
                                href={'/login'}
                            >
                                <FormattedMessage id="Intern" defaultMessage="Intern" />
                            </UniversalLink>
                        </div>
                        <div role="listitem" className="item border">

                            <UniversalLink className="item" href={'/kontakt/impressum'}>
                                <FormattedMessage id="Impressum" defaultMessage="Impressum" />
                            </UniversalLink>
                        </div>
                        <div role="listitem" className="item border">

                            <UniversalLink className="item" href={'/kontakt/datenschutz'}>
                                <FormattedMessage id="Datenschutzerklärung" defaultMessage="Datenschutzerklärung" />
                            </UniversalLink>
                        </div>
                        <div role="listitem" className="item border">
                            <UniversalLink
                                href={settings.isMultilingual ? `/${lang}/sitemap` : '/sitemap'}
                            >
                                <FormattedMessage id="Übersicht" defaultMessage="Übersicht" />
                            </UniversalLink>
                        </div>



                        <div role="listitem" className="item border">
                            <p>Copyright © 2023</p>
                        </div>
                    </List>
                </Container>

                {/* 
                <div className='ui container twelve column grid column-grid' >
                    <div className="three wide computer mobile four wide tablet column column-blocks-wrapper border">
                        <UniversalLink className="item" href={'/'}>
                            <FormattedMessage id="Aktuelles" defaultMessage="Aktuelles" />
                        </UniversalLink>
                        <br />
                        <UniversalLink className="item" href={'/'}>
                            <FormattedMessage id="Veranstaltung" defaultMessage="Veranstaltung" />
                        </UniversalLink>
                        <br />
                        <UniversalLink className="item" href={'/'}>
                            <FormattedMessage id="Pressemitteilungen" defaultMessage="Pressemitteilungen" />
                        </UniversalLink>
                        <br />
                    </div>
                    <div className="three wide computer mobile four wide tablet column column-blocks-wrapper border">
                        <UniversalLink className="item" href={'/'}>
                            <FormattedMessage id="Über HEALTHY SAXONY" defaultMessage="Über HEALTHY SAXONY" />
                        </UniversalLink>
                        <br />
                        <UniversalLink className="item" href={'/'}>
                            <FormattedMessage id="Was wollen wir" defaultMessage="Was wollen wir?" />
                        </UniversalLink>
                        <br />
                        <UniversalLink className="item" href={'/'}>
                            <FormattedMessage id="Vorstandsmitglieder" defaultMessage="Vorstandsmitglieder" />
                        </UniversalLink>
                        <br />
                        <UniversalLink className="item" href={'/'}>
                            <FormattedMessage id="Ansprechpartner" defaultMessage="Ansprechpartner" />
                        </UniversalLink>
                        <br />
                    </div>
                    <div className="three wide computer mobile four wide tablet column column-blocks-wrapper border">
                        <UniversalLink className="item" href={'/'}>
                            <FormattedMessage id="Projekte" defaultMessage="Projekte" />
                        </UniversalLink>
                        <br />
                        <UniversalLink className="item" href={'/'}>
                            <FormattedMessage id="Veranstaltung" defaultMessage="Veranstaltung" />
                        </UniversalLink>
                        <br />
                        <UniversalLink className="item" href={'/'}>
                            <FormattedMessage id="Pressemitteilungen" defaultMessage="Pressemitteilungen" />
                        </UniversalLink>
                        <br />
                    </div>
                    <div className="three wide computer mobile four wide tablet column column-blocks-wrapper border">
                        <UniversalLink className="item" href={'/'}>
                            <FormattedMessage id="Kontakt" defaultMessage="Kontakt" />
                        </UniversalLink>
                        <br />
                        <UniversalLink className="item" href={'/kontakt/impressum'}>
                            <FormattedMessage id="Impressum" defaultMessage="Impressum" />
                        </UniversalLink>
                        <br />
                        <UniversalLink className="item" href={'/kontakt/datenschutz'}>
                            <FormattedMessage id="Datenschutzerklärung" defaultMessage="Datenschutzerklärung" />
                        </UniversalLink>
                        <br />
                    </div>

                </div>


*/}
            </Segment>
        </>
    );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
    /**
     * i18n object
     */
};

export default injectIntl(Footer);
