import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { isInternalURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import NavItems from './NavItems';
import { BodyClass } from '@plone/volto/helpers';
import { Menu } from 'semantic-ui-react';

const NavItem = ({ item, lang, ismobile, close }) => {
  const [openMobileMenu, setMobileMenu] = useState(false);

  //state for second navigation => false
  const toggleMobileMenu = () => {
    setMobileMenu(!openMobileMenu);
  };

  const handleItemClick = () => {
    // You can call the `close` function to perform some action
    close();
  };
  if (!ismobile) {
    return (
      <NavLink
        to={item.url === '' ? '/' : item.url}
        key={item.url}
        className="item"
        activeClassName="active"
        exact={item.url === `/${lang}`}
      >
        {item.title}
      </NavLink>
    )
  }
  else if (isInternalURL(item.url) || item.url === '') {
    return (
      <>
        {item.items.length > 0 ? (
          <NavLink
            to={item.url === '' ? '/' : item.url}
            key={item.url}
            className="item"
            activeClassName="active"
            exact={item.url === `/${lang}`}
            onClick={toggleMobileMenu}
          >
            {item.title}
          </NavLink>
        ) : (
          <NavLink
            to={item.url === '' ? '/' : item.url}
            key={item.url}
            className="item"
            activeClassName="active"
            exact={item.url === `/${lang}`}
            onClick={handleItemClick}
          >
            {item.title}
          </NavLink>
        )}
        {openMobileMenu && isInternalURL(item.url) && (
          <div key="mobile-menu-key" className="mobile-menu second-mobile-menu">
            <BodyClass className="has-mobile-menu-open" />
            <div className="mobile-menu-nav">
              <Menu stackable pointing secondary >
                <NavItems items={item.items} lang={lang} ismobile={ismobile} close={close} />
              </Menu>
            </div>
          </div>
        )}
      </>
    );
  } else {
    return (
      <a
        href={item.url}
        key={item.url}
        className="item"
        rel="noopener noreferrer"
      >
        {item.title}
      </a>
    );
  }
};

export default NavItem;
