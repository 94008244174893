
const Schema = {
    title: 'Section block',
    fieldsets: [
        {
            id: 'default',
            title: 'Default',
            fields: ['classes', 'as', 'id', 'preview'],
        },
    ],
    properties: {
        classes: {
            title: 'Styling Klasse',
            type: 'string',
            widget: 'textarea',
        },
        as: {
            title: 'HTML5 element',
            description: 'Select HTML5 element to be used for this block',
            type: 'string',
            factory: 'Choice',
            default: 'div',
            choices: [
                ['div', 'div'],
                ['section', 'section'],
                ['article', 'article'],
                ['aside', 'aside'],
                ['details', 'details'],
            ],
        },
        id: {
            title: 'Element-ID',
            type: 'string',
            widget: 'textarea',
        },
        preview: {
            title: 'Show Preview',
            type: 'boolean',
            initialValue: false,
        },
    },
    required: [],
};

export default Schema;